module.exports = [{
      plugin: require('/Users/jzhang/PhpstormProjects/portfolio/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-118293996-1"],"pluginConfig":{"head":false}},
    },{
      plugin: require('/Users/jzhang/PhpstormProjects/portfolio/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/jzhang/PhpstormProjects/portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
